import React, { useState, useEffect } from 'react'
import axios from "axios";
import tw, { css, styled } from 'twin.macro'
import { Link } from 'gatsby'
import Layout from '../containers/Layout/Layout'
import Seo from '../components/Seo'
import PageCover from '../components/PageCover'
import BreadCrumbArrow from '../assets/svgs/breadcrumb-arrow.svg'

import ContactCoverImage from '../assets/images/contact-cover.png'
import ContactImageSrc from '../assets/images/contact-form-image.png'
import NotificationModal from '../components/NotificationModal';


const AddressContainer = styled.section`
  ${tw`px-5 py-10 lg:py-20 xl:pt-49/2 xl:pb-36 lg:px-0 max-w-screen-xl mx-auto md:flex`}
`

const AddressBox = styled.div`
  @media only screen and (min-width: 768px) {
    width: 361px;
  }

  margin-bottom: ${props => props.marginBottom};

  @media only screen and (min-width: 768px) {
    margin-right: ${props => props.marginRight};
    margin-bottom: 0;
  }
`

const BranchTitle = styled.h4`
  ${tw`capitalize font-extrabold tracking-brand font-mulish leading-tight text-3xl`}
  margin-bottom: 44px;
`

const BranchText = styled.p`
  font-family: 'Montserrat', sans-sans-serif;
  font-weight: 400;
  font-size: 13.7627px;
  line-height: 17px;
  color: #000C19;
  margin-bottom: ${props => props.marginBottom};
`
const BranchCompanyName = styled.p`
  font-family: 'Montserrat', sans-sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 13.7627px;
  line-height: 17px;
  color: #000C19;
`

const ContactFormWrapper= styled.section`
  ${tw`px-5 pb-10 pb-20 pt-10 xl:px-0 max-w-screen-xl mx-auto md:flex justify-between xl:justify-start`}
`

const ContactFormImage = styled.img`
  ${tw`w-full h-auto mb-10 md:mb-0 md:w-4/12 lg:w-5/12`}

  @media only screen and (min-width: 1280px) {
    width: 477px;
    margin-right: 83px;
  }
`

const ContactFormTitle = styled.h3`
  ${tw`font-mulish font-extrabold text-brand-dark-200 text-7/2 md:text-9/2 leading-tight tracking-brand relative inline-block mb-10 capitalize`}

  ::after {
    ${tw`absolute bg-brand-blue`}
    content: '';
    width: 100px;
    height: 4px;
    top: calc(100% + 20px);
    left: 0;
  }
`

const ContactFormContainer = styled.div`
  ${tw`md:w-3/5 lg:w-6/12`}

  @media only screen and (min-width: 1280px) {
    width: 729px;
  }
`

const ContactForm = styled.form.attrs(props => ({
  autoComplete: props.autoComplete || 'off'
}))`
  ${tw`w-full mt-8 md:mt-16`}
`

const ContactFormControl = styled.div`
  ${tw`bg-transparent w-full relative`}
  border: none;
  outline: 0;
  border-bottom: 1px solid #1A1300;
  padding: 15px 30px 4px 0;
`

const FormBottom = styled.div`
  padding: 5px 0 30px;
`

const ContactFormLabel = styled.label`
    position: absolute;
    transform-origin: top left;
    transform: translate(0, 16px) scale(1);
    transition: all .3s ease-in-out;

    font-family: Mulish;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.035em;
    text-transform: capitalize;
    color: #000;

    ${({ focused }) =>
      focused &&
      css`
        transform: translate(0, -12px) scale(0.6);
      `
    }
`

const ContactFormInput = styled.input`
  ${tw`bg-transparent w-full`}
  border: none;
  outline: 0;
  padding-top: 10px;
  font-family: Mulish;
  height: 43px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.035em;
  color: #000;
`
const ContactFormTextInput = styled.textarea`
  ${tw`bg-transparent w-full`}
  border: none;
  outline: 0;
  padding-top: 10px;
  font-family: Mulish;
  height: 121px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.035em;
  color: #000;
`
const Button = styled.button`
  ${tw`border-0 text-white outline-none font-bold font-lato tracking-brand bg-brand-blue`};
  padding: 12px 55px;
  outline: 0;

  &:focus,
  &:hover,
  &:active {
    background-color: #254ac4;
    outline: none;
    border: none;
  }
`

const ValidationText = styled.p`
  ${tw`font-lato text-left`}
  color: red;
  font-size: 16px;
`

const Failed = styled.p`
  ${tw`font-lato text-center`}
  color: red;
  font-size: 16px;
  display: none;

  ${({ show }) =>
    show &&
    css`
      display: block;
    `
  }
`


const Contact = () => {
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [timeLeft, setTimeLeft] = useState(null);
  const [failed, showFailed] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  

  const [inputs, setInputs] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  })

  const [focus, setFocus] = useState({
    fullName: false,
    email: false,
    subject: false,
    message: false,
  });

  const handleOnChange = prop => event => {
    setInputs({
      ...inputs,
      [prop]: event.target.value
    });
  };
  

  const handleBlur = prop => event => {
    if(!event.target.value) {
      setFocus({
        ...focus,
        [prop]: false
      })
    }
  }

  const handleFocus = prop => event => {
    setFocus({
      ...focus,
      [prop]: true
    })
  }

  const validationRules = {
    fullName: val => !!val,
    email: val => val && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val),
    subject: val => !!val,
    message: val => !!val // similar to "required"
  };

  const validate = () => {
    let errors = {};
    let hasErrors = false;
    for (let key of Object.keys(inputs)) {
      errors[key] = !validationRules[key](inputs[key]);
      hasErrors |= errors[key];
    }
    setFieldErrors(prev => ({ ...prev, ...errors }));
    return !hasErrors;
  };

  const renderFieldError = field => {
    if (fieldErrors[field]) {
      if (field === "fullName") {
        return <ValidationText>Please enter a valid full name.</ValidationText>;
      } else {
        return <ValidationText>Please enter a valid {field}.</ValidationText>;
      }
      
    }
  };


  const closeModal = () => {
    setModal(false);
    setModalMessage("");
  }



  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });

  const handleServerResponse = (ok, msg) => {
    setFieldErrors({});
    setServerState({
      submitting: false,
      status: { ok, msg }
    });

    if (ok) {
      setInputs({
        fullName: "",
        email: "",
        subject: "",
        message: ""
      });
      setFocus({
        fullName: false,
        email: false,
        subject: false,
        message: false,
      })
      setTimeLeft(5)
      setModal(true)
      setModalMessage("Thank you for contacting us, We will get back to you");

      setTimeout(() => {
        setModal(false);
        setModalMessage("")
      }, 5000)

    }

    if (!ok) {
      showFailed(true)
      setTimeout(() => {
        showFailed(false)
      }, 5000)
    }

  };
  const handleSubmit = event => {
    event.preventDefault();
    if (!validate()) {
      return;
    }

    setServerState({ submitting: true });
    axios({
      method: "POST",
      url: "https://formspree.io/f/xeqvkpre",
      data: inputs
    })
      .then(r => {
        handleServerResponse(true, "Thanks!");
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error);
        
      });
  };

  useEffect(() => {
    if (Object.keys(fieldErrors).length > 0) {
      validate();
    }
  }, [inputs]);

  useEffect(() => {
    if (modal) {

      if (!timeLeft) return;

      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000);

      return () => clearInterval(intervalId)
    }

  },[modal, timeLeft]);

  

  return (
    <Layout>
    <Seo title="Contact Us" />
    <PageCover
      imageSrc={ContactCoverImage}
      coverTitle="Contact Us"
    >
      <Link to="/">Home</Link>
      <div>
        <BreadCrumbArrow />
      </div>
      <Link to="/contact-us">Contact Us</Link>
    </PageCover>
    <AddressContainer>
      <AddressBox marginRight="104px" marginBottom="50px">
        <BranchTitle>
          Corporate Headquarters
        </BranchTitle>
        <BranchCompanyName>
          Manz Incorporated
        </BranchCompanyName>
        <BranchText marginBottom="20px">
          4C Georges Close, by 5 Nkpogu Road, Off TRans Amadi Industrial Layout , Port Harcourt, Rivers State, Nigeria 
        </BranchText>
        <BranchText marginBottom="5px">
        </BranchText>
        <BranchText>
          sales@manzincorporated.com
        </BranchText>
      </AddressBox>
      <AddressBox>
        <BranchTitle>
          Divisional Headquarters
        </BranchTitle>
        <BranchCompanyName>
          Manz Incorporated
        </BranchCompanyName>
        <BranchText marginBottom="35px">
        28,  Jehovah Witness Road, beside Cedar Grow School, Bogije , Ibeju-Lekki, Lagos. 
        </BranchText>
        <BranchText>
          sales@manzincorporated.com
        </BranchText>
      </AddressBox>
    </AddressContainer>

    {
      modal && (
        <NotificationModal active={modal} successMessage={modalMessage} closeModal={closeModal} time={timeLeft}/>
      )
    }
    <ContactFormWrapper>
      <ContactFormImage src={ContactImageSrc} />
      <ContactFormContainer>
        <ContactFormTitle>
          Talk To Us
        </ContactFormTitle>
        <ContactForm autocomplete="off" onSubmit={handleSubmit} noValidate>

          <ContactFormControl onFocus={handleFocus("fullName")} onBlur={handleBlur("fullName")}>
            <ContactFormLabel onFocus={handleFocus("fullName")} focused={focus.fullName}>Full Name</ContactFormLabel>
            <ContactFormInput onFocus={handleFocus("fullName")} name="Full Name" type="text" value={inputs.fullName} onChange={handleOnChange('fullName')} />
          </ContactFormControl>
          <FormBottom>
            {renderFieldError("fullName")}
          </FormBottom>
          

          <ContactFormControl onFocus={handleFocus("email")} onBlur={handleBlur("email")}>
            <ContactFormLabel onFocus={handleFocus("email")} focused={focus.email}>Email Address</ContactFormLabel>
            <ContactFormInput onFocus={handleFocus("email")} name="Email Address" type="email" value={inputs.email} onChange={handleOnChange('email')}/>
          </ContactFormControl>
          <FormBottom>
            {renderFieldError("email")}
          </FormBottom>
          

          <ContactFormControl onFocus={handleFocus("subject")} onBlur={handleBlur("subject")}>
            <ContactFormLabel onFocus={handleFocus("subject")} focused={focus.subject}>Subject</ContactFormLabel>
            <ContactFormInput onFocus={handleFocus("subject")} name="Subject" type="text" value={inputs.subject} onChange={handleOnChange('subject')}/>
          </ContactFormControl>
          <FormBottom>
            {renderFieldError("subject")}
          </FormBottom>
          

          <ContactFormControl onFocus={handleFocus("message")} onBlur={handleBlur("message")}>
            <ContactFormLabel onFocus={handleFocus("message")} focused={focus.message}>Message</ContactFormLabel>
            <ContactFormTextInput onFocus={handleFocus("message")} cols="20" row="10" name="Message" type="text" value={inputs.message} onChange={handleOnChange('message')}/>
          </ContactFormControl>
          <FormBottom>
            {renderFieldError("message")}
          </FormBottom>

          <Button type="submit" disabled={serverState.submitting}>Send</Button>

          {
            failed && (
              <Failed show={failed}>{serverState.status.msg}</Failed>
            )
          }

        </ContactForm>
      </ContactFormContainer>
    </ContactFormWrapper>

    
  </Layout>
  )
}


export default Contact;